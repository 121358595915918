import PropTypes from 'prop-types';

const FlameDecal = ({ colorTheme, ...rest }) => {
  return (
    <svg
      width="310"
      height="98"
      viewBox="0 0 310 96"
      fill="none"
      pointerEvents="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path d="M0 51.3125L310 96.3767V97.278H0V51.3125Z" fill={colorTheme.diagonalColor} data-testid="diagonal" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M34.3109 5.76062C50.5104 10.4748 55.4983 23.9591 54.4435 34.8172C53.8708 40.7316 52.2848 45.5022 50.5631 48.1585C50.5323 48.2045 50.5337 48.265 50.5666 48.3096C50.5995 48.3542 50.6568 48.3735 50.7101 48.3579C55.1329 47.12 58.1769 42.7745 58.9793 41.5142C59.0175 41.4399 59.0973 41.3965 59.1805 41.4048C59.2637 41.4131 59.3333 41.4714 59.356 41.5518C59.8383 42.8084 61.1907 47.1276 60.0492 53.6853C57.9885 65.5367 51.7197 67.9822 44.8556 73.1743C38.745 77.7944 42.324 81.6433 44.5693 83.3138C44.6965 83.4058 44.7467 83.5711 44.6924 83.7182C44.638 83.8653 44.4923 83.9583 44.3358 83.9458C18.5861 82.2942 6.3461 60.0399 21.7394 37.7894C24.708 33.4853 28.464 29.6553 32.7211 24.403C35.1586 21.3932 39.3478 11.9873 34.1376 6.02399C34.0925 5.968 34.0887 5.88933 34.1282 5.82927C34.1677 5.7692 34.2415 5.74147 34.3109 5.76062ZM22.199 0.0531498C23.2655 1.59288 24.6504 2.88614 26.2601 3.84559C33.7948 8.11209 31.8358 16.3704 31.1162 18.6655C31.0183 18.9815 30.9053 18.9664 30.8751 18.6354C30.6092 15.8039 28.9314 13.5415 27.4204 12.0017L27.0136 11.5998C22.3949 7.99169 22.0897 2.40461 21.9729 0.132159C21.9693 0.0765627 22.0033 0.0253848 22.0559 0.00698043C22.1086 -0.011424 22.1671 0.00745199 22.199 0.0531498Z"
        fill={colorTheme.topIconColor}
        data-testid="top-icon"
      />
      <mask id="mask0_5123_1485" maskUnits="userSpaceOnUse" x="0" y="51" width="310" height="47">
        <path d="M0 51.3125L310 96.3767V97.278H0V51.3125Z" fill="white" />
      </mask>
      <g mask="url(#mask0_5123_1485)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M34.3109 5.76062C50.5104 10.4748 55.4983 23.9591 54.4435 34.8172C53.8708 40.7316 52.2848 45.5022 50.5631 48.1585C50.5323 48.2045 50.5337 48.265 50.5666 48.3096C50.5995 48.3542 50.6568 48.3735 50.7101 48.3579C55.1329 47.12 58.1769 42.7745 58.9793 41.5142C59.0175 41.4399 59.0973 41.3965 59.1805 41.4048C59.2637 41.4131 59.3333 41.4714 59.356 41.5518C59.8383 42.8084 61.1907 47.1276 60.0492 53.6853C57.9885 65.5367 51.7197 67.9822 44.8556 73.1743C38.745 77.7944 42.324 81.6433 44.5693 83.3138C44.6965 83.4058 44.7467 83.5711 44.6924 83.7182C44.638 83.8653 44.4923 83.9583 44.3358 83.9458C18.5861 82.2942 6.3461 60.0399 21.7394 37.7894C24.708 33.4853 28.464 29.6553 32.7211 24.403C35.1586 21.3932 39.3478 11.9873 34.1376 6.02399C34.0925 5.968 34.0887 5.88933 34.1282 5.82927C34.1677 5.7692 34.2415 5.74147 34.3109 5.76062ZM22.199 0.0531498C23.2655 1.59288 24.6504 2.88614 26.2601 3.84559C33.7948 8.11209 31.8358 16.3704 31.1162 18.6655C31.0183 18.9815 30.9053 18.9664 30.8751 18.6354C30.6092 15.8039 28.9314 13.5415 27.4204 12.0017L27.0136 11.5998C22.3949 7.99169 22.0897 2.40461 21.9729 0.132159C21.9693 0.0765627 22.0033 0.0253848 22.0559 0.00698043C22.1086 -0.011424 22.1671 0.00745199 22.199 0.0531498Z"
          fill={colorTheme.bottomIconColor}
          data-testid="bottom-icon"
        />
      </g>
    </svg>
  );
};

FlameDecal.propTypes = {
  colorTheme: PropTypes.shape({
    bottomIconColor: PropTypes.string.isRequired,
    diagonalColor: PropTypes.string.isRequired,
    topIconColor: PropTypes.string.isRequired,
  }).isRequired,
};

export { FlameDecal };
