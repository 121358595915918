import { CMS_BLOCK_THEME_COLOR, colors } from '@nandosaus/constants';

const { BLUE, ...BLOCK_THEME_COLOR } = CMS_BLOCK_THEME_COLOR;

const BLOCK_PATTERN_COLOR = {
  [CMS_BLOCK_THEME_COLOR.GREEN]: {
    primary: colors.green400,
    secondary: colors.neonTeal800,
    tertiary: colors.neonTeal400,
  },
  [CMS_BLOCK_THEME_COLOR.PINK]: {
    primary: colors.neonPink,
    secondary: colors.pink700,
    tertiary: colors.neonRed,
  },
  [CMS_BLOCK_THEME_COLOR.ORANGE]: {
    primary: colors.orange500,
    secondary: colors.orange800,
    tertiary: colors.yellow600,
  },
};

const BLOCK_BACKGROUND_PATTERN_COLOR = {
  [CMS_BLOCK_THEME_COLOR.GREEN]: {
    bottomLeft: colors.yellow400,
    bottomCentre: colors.neonTeal400,
    bottomRight: colors.green400,
    leftBottom: colors.green400,
    leftTop: colors.yellow400,
    right: colors.neonTeal800,
  },
  [CMS_BLOCK_THEME_COLOR.ORANGE]: {
    bottomLeft: colors.yellow400,
    bottomCentre: colors.orange500,
    bottomRight: colors.yellow400,
    leftBottom: colors.neonRed,
    leftTop: colors.yellow400,
    right: colors.orange700,
  },
  [CMS_BLOCK_THEME_COLOR.PINK]: {
    bottomLeft: colors.pink300,
    bottomCentre: colors.pink700,
    bottomRight: colors.pink200,
    leftBottom: colors.neonPink,
    leftTop: colors.yellow100,
    right: colors.neonPink,
  },
};

export { BLOCK_PATTERN_COLOR, BLOCK_BACKGROUND_PATTERN_COLOR, BLOCK_THEME_COLOR };
