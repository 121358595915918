import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { assetQuery, buttonQuery, matrixBlockQuery } from '../../../content/query-snippets';
import { Breadcrumbs } from '../../breadcrumbs';
import { contentImageDefaultProps, contentImagePropTypes } from '../prop-types';
import { ContentLayout } from '../content-layout';
import { Flex } from '../../grid';
import { H1 } from '../../typography';
import { Image } from '../../image';
import { imageTransform } from '../../../content/transforms';
import { overlayStyles, StyledContainer, StyledRow, wrapperStyles } from '../styles';

const StackedPageHeader = ({ backgroundColor, image, breadcrumbs, children, heading }) => {
  const transformedImage = imageTransform(image);

  return (
    <Wrapper backgroundColor={backgroundColor} flexDirection="column">
      <StyledContainer pt="1rem">
        {!isEmpty(transformedImage) && (
          <Image
            alt={transformedImage.alt}
            focalPoint={transformedImage.focalPoint}
            maxHeight="50vh"
            isBackground
            position="relative"
            src={transformedImage.url}
            srcSet={transformedImage.srcset}
          />
        )}
        {!!breadcrumbs && <Breadcrumbs entry={breadcrumbs} />}
        <StyledRow py="3rem">
          <ContentLayout contentAlignment="center" contentPosition="center" isPageHeader>
            {!!heading && <H1 pb={1}>{heading}</H1>}
            {children}
          </ContentLayout>
        </StyledRow>
      </StyledContainer>
    </Wrapper>
  );
};

const Wrapper = styled(Flex)`
  ${wrapperStyles}
  ${overlayStyles}
`;

StackedPageHeader.query = `
  ... on contentMatrix_pageHeaderBlock_BlockType {
    ${matrixBlockQuery}
    ${buttonQuery}
    contentPosition
    heading
    ${assetQuery('backgroundImage')}
  }
`;

StackedPageHeader.propTypes = {
  backgroundColor: contentImagePropTypes.backgroundColor,
  breadcrumbs: PropTypes.shape({
    title: PropTypes.string,
    ancestors: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  children: PropTypes.node,
  heading: contentImagePropTypes.heading,
  image: contentImagePropTypes.image,
};

StackedPageHeader.defaultProps = {
  backgroundColor: 'dark',
  breadcrumbs: null,
  children: null,
  heading: contentImageDefaultProps.heading,
  image: contentImageDefaultProps.image,
};

export { StackedPageHeader };
