import { DECAL_TYPE, DECAL_TYPES } from '@nandosaus/constants';
import PropTypes from 'prop-types';

import { FlameDecal } from './components/flame-decal';
import { HeartDecal } from './components/heart-decal';
import { PepperDecal } from './components/pepper-decal';

const BottomDiagonalDecal = ({ decalType, colorTheme, ...rest }) => {
  switch (decalType) {
    case DECAL_TYPE.FLAME:
      return <FlameDecal colorTheme={colorTheme} {...rest} />;
    case DECAL_TYPE.PEPPER:
      return <PepperDecal colorTheme={colorTheme} {...rest} />;
    case DECAL_TYPE.HEART:
      return <HeartDecal colorTheme={colorTheme} {...rest} />;
    default:
      return null;
  }
};

BottomDiagonalDecal.propTypes = {
  decalType: PropTypes.oneOf(DECAL_TYPES).isRequired,
  colorTheme: PropTypes.shape({
    bottomIconColor: PropTypes.string,
    diagonalColor: PropTypes.string,
    topIconColor: PropTypes.string,
  }).isRequired,
};

export { BottomDiagonalDecal };
