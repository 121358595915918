import { colors } from '@nandosaus/constants';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { globalStyles } from '../../constants/global';
import { headingSize } from '../../constants';
import { P } from '../typography';

const TiltedHeading = ({ color, children, fontSize, isSmall, padding }) => (
  <TiltedBackground backgroundColor={color} padding={padding}>
    {isSmall ? <StyledP variant={4}>{children}</StyledP> : <StyledH3 fontSize={fontSize}>{children}</StyledH3>}
  </TiltedBackground>
);

const TiltedBackground = styled.div`
  display: inline-block;
  background-color: ${({ backgroundColor }) => backgroundColor};
  border-radius: 3.3px;
  padding: ${({ padding }) => padding};
  transform: rotate(-3deg);
`;

const textStyles = `
  font-family: ${globalStyles.brandFontFamily};
  font-weight: 400;
  margin: 0;
`;

const StyledH3 = styled.h3`
  font-size: ${({ fontSize }) => fontSize};
  ${textStyles}
`;

const StyledP = styled(P)`
  ${textStyles}
`;

TiltedHeading.propTypes = {
  color: PropTypes.string,
  children: PropTypes.node.isRequired,
  fontSize: PropTypes.string,
  isSmall: PropTypes.bool,
  padding: PropTypes.string,
};

TiltedHeading.defaultProps = {
  color: colors.success400,
  fontSize: headingSize.LxMxS3,
  isSmall: false,
  padding: '0.5rem 1rem',
};

export { TiltedHeading };
