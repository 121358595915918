import { CMS_BLOCK_PATTERN_STYLE } from '@nandosaus/constants';
import { isEmpty, values } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { BLOCK_THEME_COLOR } from './content-image-block/constants';
import { cmsImagePropTypes } from '../../prop-types';
import { Col } from '../grid';
import { contentImageDefaultProps, contentImagePropTypes } from './prop-types';
import { ContentLayout } from './content-layout';
import { Image } from '../image';
import { mediaColStyles, StyledContainer, StyledRow } from './styles';
import { Slant, SmallPattern } from './content-image-block/components/patterns';

const ContentImageLayout = ({
  showBackgroundPattern,
  children,
  contentPosition,
  contentAlignment,
  image,
  isPageHeader,
  flexDirection,
  py,
  shouldHideImageOnMobile,
  themeColor,
  patternStyle,
}) => {
  // if content is right then image position is left
  const imageOffset = contentPosition === 'left' ? [0, 0, 0, 1 / 12, 1 / 12] : [0];
  const hasImage = !isEmpty(image);

  return (
    <StyledContainer showBackgroundPattern={showBackgroundPattern}>
      <StyledRow
        showBackgroundPattern={showBackgroundPattern}
        py={showBackgroundPattern ? 0 : py}
        flexDirection={flexDirection}
      >
        {hasImage && (
          <MediaCol
            contentPosition={contentPosition}
            hasImage={hasImage}
            isPageHeader={isPageHeader}
            offset={!showBackgroundPattern ? imageOffset : null}
            width={{ xs: 1, lg: 6 / 12 }}
            display={{ xs: shouldHideImageOnMobile ? 'none' : 'block', lg: 'block' }}
          >
            {!showBackgroundPattern && patternStyle && themeColor && (
              <SmallPattern themeColor={themeColor} contentPosition={contentPosition} patternStyle={patternStyle} />
            )}
            <Image
              alt={image.alt}
              focalPoint={image.focalPoint}
              isBackground
              objectFit={image.objectFit}
              src={image.url}
              srcSet={image.srcset}
              width={image.width}
              height={image.height}
            />
          </MediaCol>
        )}
        <ContentLayout
          showBackgroundPattern={showBackgroundPattern}
          contentAlignment={isPageHeader ? 'center' : contentAlignment}
          contentPosition={isPageHeader && !hasImage ? 'center' : contentPosition}
          hasImage={hasImage}
          isPageHeader={isPageHeader}
          themeColor={themeColor}
        >
          {children}
        </ContentLayout>
        {showBackgroundPattern && <Slant />}
      </StyledRow>
    </StyledContainer>
  );
};

const MediaCol = styled(Col)`
  ${mediaColStyles}
  position: relative
`;

ContentImageLayout.propTypes = {
  showBackgroundPattern: PropTypes.bool,
  children: contentImagePropTypes.children,
  contentAlignment: contentImagePropTypes.contentAlignment,
  contentPosition: contentImagePropTypes.contentPosition,
  flexDirection: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
  image: cmsImagePropTypes,
  isPageHeader: PropTypes.bool,
  py: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  shouldHideImageOnMobile: PropTypes.bool,
  themeColor: PropTypes.oneOf(values(BLOCK_THEME_COLOR)),
  patternStyle: PropTypes.oneOf(values(CMS_BLOCK_PATTERN_STYLE)),
};

ContentImageLayout.defaultProps = {
  showBackgroundPattern: false,
  children: contentImageDefaultProps.children,
  contentAlignment: contentImageDefaultProps.contentAlignment,
  contentPosition: contentImageDefaultProps.contentPosition,
  flexDirection: 'row',
  image: [],
  isPageHeader: false,
  py: 3,
  shouldHideImageOnMobile: false,
  themeColor: BLOCK_THEME_COLOR.ORANGE,
  patternStyle: undefined,
};

export { ContentImageLayout };
