import PropTypes from 'prop-types';
import React from 'react';

import { Breadcrumbs } from '../breadcrumbs';
import { ContentLayout } from './content-layout';
import { HeadingWithLineHighlights } from '../heading-with-line-highlights';
import { StyledContainer, StyledRow } from './styles';

const ContentSubHeader = ({ title, breadcrumbs, children }) => (
  <StyledContainer>
    {breadcrumbs && <Breadcrumbs entry={breadcrumbs} />}
    {(title || children) && (
      <StyledRow>
        <ContentLayout contentAlignment="center" contentPosition="center" isPageHeader>
          {title && <HeadingWithLineHighlights heading={title} />}
          {children}
        </ContentLayout>
      </StyledRow>
    )}
  </StyledContainer>
);

ContentSubHeader.propTypes = {
  title: PropTypes.string,
  breadcrumbs: PropTypes.shape({
    title: PropTypes.string,
    ancestors: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  children: PropTypes.node,
};

ContentSubHeader.defaultProps = {
  title: null,
  breadcrumbs: null,
  children: null,
};

export { ContentSubHeader };
