import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { Body, wrapperStyles } from '../styles';
import { Button } from '../../button';
import { buttonPropTypes, cmsImagesPropTypes } from '../../../prop-types';
import { buttonQuery, matrixBlockQuery } from '../../../content/query-snippets';
import { buttonTransform } from '../../../content/transforms';
import { CollageImageLayout } from './components/collage-image-layout';
import { ContentLayout } from '../content-layout';
import { Flex } from '../../grid';
import { H2, RichText } from '../../typography';

const BLOCK_MIN_HEIGHT = '35vh';

const ContentImageCollageBlock = ({ body, button, heading, collageImages }) => {
  const transformedButton = buttonTransform(button);
  const hasValidImages = collageImages.length >= 5;

  return (
    <Wrapper minHeight={BLOCK_MIN_HEIGHT} mt="4rem" mb="3rem">
      <ContentLayout hasImage={hasValidImages} isPageHeader width={{ xs: 12 / 12, md: 8 / 12, lg: 5 / 12, xl: 4 / 12 }}>
        {!!heading && <H2 pb={1}>{heading}</H2>}
        {!!body && (
          <Body textAlign="center">
            <RichText keepMargins>{body}</RichText>
          </Body>
        )}
        {!isEmpty(transformedButton) && (
          <Button mt="2rem" mb="3rem" {...transformedButton}>
            {transformedButton.text}
          </Button>
        )}
      </ContentLayout>
      <CollageImageLayout collageImages={collageImages} />
    </Wrapper>
  );
};

const Wrapper = styled(Flex)`
  ${wrapperStyles}
`;

ContentImageCollageBlock.propTypes = {
  heading: PropTypes.string,
  body: PropTypes.string,
  button: PropTypes.oneOfType([PropTypes.arrayOf(buttonPropTypes), buttonPropTypes]),
  collageImages: PropTypes.arrayOf(cmsImagesPropTypes),
};

ContentImageCollageBlock.defaultProps = {
  heading: '',
  body: null,
  button: null,
  collageImages: [],
};

ContentImageCollageBlock.query = `
  ... on contentMatrix_contentImageCollageBlock_BlockType {
    ${matrixBlockQuery}
    body
    ${buttonQuery}
    heading
    collageImages {
      ... on assets_Asset {
        alt
        url
        lo: url @transform(handle: "block1x")
        hi: url @transform(handle: "block2x")
        focalPoint
      }
    }
  }
`;

export { ContentImageCollageBlock };
