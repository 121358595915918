import PropTypes from 'prop-types';
import React from 'react';

import { cmsImagesPropTypes } from '../../../../../prop-types';
import { Col, Row } from '../../../../grid';
import { Image } from '../../../../image';
import { imageTransform } from '../../../../../content/transforms';

const getImageProps = image => {
  const transformedImage = imageTransform([image]);
  return {
    alt: transformedImage.alt,
    focalPoint: transformedImage.focalPoint,
    src: transformedImage.url,
    srcSet: transformedImage.srcSet,
    height: '100%',
    width: '100%',
    objectFit: 'cover',
  };
};

const CollageImageLayout = ({ collageImages }) =>
  collageImages.length >= 5 && (
    <Col
      width={{ xs: 12 / 12, md: 12 / 12, lg: 5 / 12 }}
      offset={[0, 0, 0, 1 / 12]}
      maxHeight={{ xs: '60rem', md: '40rem', lg: '35rem', xxl: '30rem' }}
      contentPosition="right"
    >
      <Row pb="1rem" height={{ xs: '25%', sm: '35%' }}>
        <Col width={{ xs: 12 / 12, md: 5 / 12, lg: 4 / 12 }}>
          <Image {...getImageProps(collageImages[0])} />
        </Col>
        <Col width={{ sm: 7 / 12, lg: 8 / 12 }} display={{ xs: 'none', sm: 'block' }}>
          <Image {...getImageProps(collageImages[1])} />
        </Col>
      </Row>
      <Row pb="1rem" height={{ xs: '50%', sm: '65%' }}>
        <Col width={{ xs: 6 / 12, xl: 7 / 12 }}>
          <Image {...getImageProps(collageImages[2])} />
        </Col>
        <Col width={{ xs: 6 / 12, xl: 5 / 12 }}>
          <Row height="50%" pb="1rem">
            <Col width={12 / 12}>
              <Image {...getImageProps(collageImages[3])} />
            </Col>
          </Row>
          <Row height="50%">
            <Col width={12 / 12}>
              <Image {...getImageProps(collageImages[4])} />
            </Col>
          </Row>
        </Col>
      </Row>
      <Row height={{ xs: '25%', sm: '35%' }} display={{ xs: 'block', sm: 'none' }}>
        <Col width={12 / 12}>
          <Image {...getImageProps(collageImages[1])} />
        </Col>
      </Row>
    </Col>
  );

CollageImageLayout.propTypes = {
  collageImages: PropTypes.arrayOf(cmsImagesPropTypes).isRequired,
};

export { CollageImageLayout };
