import { CMS_BLOCK_PATTERN_STYLE } from '@nandosaus/constants';
import { values } from 'lodash';
import PropTypes from 'prop-types';

import { BLOCK_THEME_COLOR } from './content-image-block/constants';
import { buttonPropTypes, cmsImagesPropTypes } from '../../prop-types';

const contentImagePropTypes = {
  backgroundColor: PropTypes.oneOf(['light', 'dark']),
  backgroundImage: cmsImagesPropTypes,
  body: PropTypes.string,
  button: PropTypes.oneOfType([PropTypes.arrayOf(buttonPropTypes), buttonPropTypes]),
  children: PropTypes.node,
  contentAlignment: PropTypes.oneOf(['left', 'center']),
  contentPosition: PropTypes.oneOf(['left', 'center', 'right']),
  heading: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  image: cmsImagesPropTypes,
  patternStyle: PropTypes.oneOf(values(CMS_BLOCK_PATTERN_STYLE)),
  showOverlay: PropTypes.bool,
  stackOnMobile: PropTypes.bool,
  subheading: PropTypes.string,
  themeColor: PropTypes.oneOf(values(BLOCK_THEME_COLOR)),
};

const contentImageDefaultProps = {
  backgroundColor: 'light',
  backgroundImage: null,
  body: null,
  button: null,
  children: null,
  contentAlignment: 'center',
  contentPosition: 'left',
  heading: '',
  height: '35vh',
  image: null,
  patternStyle: CMS_BLOCK_PATTERN_STYLE.TRIANGLE,
  showOverlay: false,
  stackOnMobile: false,
  subheading: null,
  themeColor: BLOCK_THEME_COLOR.GREEN,
};

export { contentImagePropTypes, contentImageDefaultProps };
