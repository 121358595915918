import PropTypes from 'prop-types';

const HeartDecal = ({ colorTheme, ...rest }) => {
  return (
    <svg
      width="310"
      height="87"
      viewBox="0 0 310 86"
      fill="none"
      pointerEvents="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path d="M0 39.4141L310 85.5715V86.4946H0V39.4141Z" fill={colorTheme.diagonalColor} data-testid="diagonal" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M57.2644 16.6386C58.62 26.902 56.6818 49.2209 55.259 65.64C54.4411 75.1354 53.8361 82.1173 53.8361 82.1173L18.9599 35.2803C18.9599 35.2803 2.68127 14.9979 15.8005 6.64288C18.2204 5.56068 28.3931 -0.129569 38.3866 28.4032C38.3866 28.4032 37.3783 19.0009 37.2663 15.8474C37.087 11.4837 37.3335 2.41883 45.1087 0.347528C45.8817 0.13807 54.161 -3.27143 57.2644 16.6386Z"
        fill={colorTheme.topIconColor}
        data-testid="top-icon"
      />
      <mask id="mask0_5123_1454" maskUnits="userSpaceOnUse" x="0" y="39" width="310" height="48">
        <path d="M0 39.4141L310 85.5715V86.4946H0V39.4141Z" fill="white" />
      </mask>
      <g mask="url(#mask0_5123_1454)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M57.2644 16.6386C58.62 26.902 56.6818 49.2209 55.259 65.64C54.4411 75.1354 53.8361 82.1173 53.8361 82.1173L18.9599 35.2803C18.9599 35.2803 2.68127 14.9979 15.8005 6.64288C18.2204 5.56068 28.3931 -0.129569 38.3866 28.4032C38.3866 28.4032 37.3783 19.0009 37.2663 15.8474C37.087 11.4837 37.3335 2.41883 45.1087 0.347528C45.8817 0.13807 54.161 -3.27143 57.2644 16.6386Z"
          fill={colorTheme.bottomIconColor}
          data-testid="bottom-icon"
        />
      </g>
    </svg>
  );
};

HeartDecal.propTypes = {
  colorTheme: PropTypes.shape({
    bottomIconColor: PropTypes.string.isRequired,
    diagonalColor: PropTypes.string.isRequired,
    topIconColor: PropTypes.string.isRequired,
  }).isRequired,
};

export { HeartDecal };
