import { colors } from '@nandosaus/constants';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { Arrow } from './components/arrow';
import { TiltedHeading } from '../tilted-heading';

const TiltedTag = ({ arrowPosition, children }) => (
  <Container>
    <ArrowContainer arrowPosition={arrowPosition}>
      <Arrow />
    </ArrowContainer>
    <TiltedHeading isSmall color={colors.orange300} padding="0.25rem 0.5rem">
      {children}
    </TiltedHeading>
  </Container>
);

const Container = styled.div`
  position: relative;
`;

const ArrowContainer = styled.div`
  position: absolute;
  top: ${({ arrowPosition }) => (arrowPosition === 'bottom' ? '91%' : '-40%')};
  transform: ${({ arrowPosition }) => (arrowPosition === 'bottom' ? 'rotate(0deg)' : 'rotate(90deg)')};
  left: ${({ arrowPosition }) => (arrowPosition === 'bottom' ? '-10%' : '-18%')};
`;

TiltedTag.propTypes = {
  arrowPosition: PropTypes.oneOf(['top', 'bottom']),
  children: PropTypes.node.isRequired,
};

TiltedTag.defaultProps = {
  arrowPosition: 'bottom',
};

export { TiltedTag };
