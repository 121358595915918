import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import styled, { css } from 'styled-components';

import { assetQuery, buttonQuery, matrixBlockQuery } from '../../../content/query-snippets';
import { BackgroundImage, Body, overlayStyles, StyledContainer, StyledRow, wrapperStyles } from '../styles';
import { Button } from '../../button';
import { buttonTransform, imageTransform } from '../../../content/transforms';
import { contentImageDefaultProps, contentImagePropTypes } from '../prop-types';
import { ContentLayout } from '../content-layout';
import { Flex } from '../../grid';
import { H2, RichText } from '../../typography';

const BLOCK_MIN_HEIGHT = '35vh';

const FeatureBlock = ({
  backgroundImage,
  body,
  button,
  contentAlignment,
  contentPosition,
  heading,
  showOverlay,
  stackOnMobile,
  textTheme,
}) => {
  const transformedBackgroundImage = imageTransform(backgroundImage);
  const transformedButton = buttonTransform(button);

  return (
    <Wrapper
      minHeight={BLOCK_MIN_HEIGHT}
      showOverlay={showOverlay}
      showOverlayOnMobile={!stackOnMobile}
      textTheme={textTheme}
    >
      {transformedBackgroundImage && (
        <BackgroundImage
          alt={transformedBackgroundImage.alt}
          height="40vh"
          isBackground
          focalPoint={transformedBackgroundImage.focalPoint}
          src={transformedBackgroundImage.url}
          srcSet={transformedBackgroundImage.srcset}
          stackOnMobile={stackOnMobile}
        />
      )}
      <StyledContainer mx="3rem">
        <StyledRow>
          <ContentLayout contentAlignment="center" contentPosition={contentPosition}>
            {heading && <H2 pb={1}>{heading}</H2>}
            {!!body && (
              <Body textAlign={contentAlignment}>
                <RichText keepMargins>{body}</RichText>
              </Body>
            )}
            {!isEmpty(transformedButton) && (
              <Button mt="2rem" {...transformedButton}>
                {transformedButton.text}
              </Button>
            )}
          </ContentLayout>
        </StyledRow>
      </StyledContainer>
    </Wrapper>
  );
};

const Wrapper = styled(Flex)`
  ${wrapperStyles}
  ${overlayStyles}

  ${({ textTheme, theme, showOverlay }) =>
    !showOverlay &&
    css`
      color: ${textTheme === 'light' ? theme.colors.white : theme.colors.black};

      svg {
        fill: ${textTheme === 'light' ? theme.colors.white : theme.colors.black};
      }
    `}
`;

FeatureBlock.query = `
  ... on contentMatrix_featureBlock_BlockType {
    ${matrixBlockQuery}
    ${assetQuery('backgroundImage')}
    ${buttonQuery}
    body
    contentAlignment
    contentPosition
    heading
    showOverlay
    stackOnMobile
    textTheme
  }
`;

FeatureBlock.propTypes = {
  backgroundImage: contentImagePropTypes.backgroundImage,
  body: contentImagePropTypes.body,
  button: contentImagePropTypes.button,
  contentAlignment: contentImagePropTypes.contentPosition,
  contentPosition: contentImagePropTypes.contentPosition,
  heading: contentImagePropTypes.heading,
  showOverlay: contentImagePropTypes.showOverlay,
  stackOnMobile: contentImagePropTypes.stackOnMobile,
  textTheme: PropTypes.string,
};

FeatureBlock.defaultProps = {
  backgroundImage: contentImageDefaultProps.backgroundImage,
  body: contentImageDefaultProps.body,
  button: contentImageDefaultProps.button,
  contentAlignment: contentImageDefaultProps.contentAlignment,
  contentPosition: contentImageDefaultProps.contentPosition,
  heading: contentImageDefaultProps.heading,
  showOverlay: contentImageDefaultProps.showOverlay,
  stackOnMobile: contentImageDefaultProps.stackOnMobile,
  textTheme: 'light',
};

export { FeatureBlock };
