import { camelCase, isEmpty, isNil, map } from 'lodash';
import { propType as systemPropType } from '@styled-system/prop-types';

import { Breadcrumb } from './breadcrumb';
import { breadcrumbPropTypes } from '../../prop-types';
import { Flex } from '../grid';
import { navigation } from '../../constants';
import { P } from '../typography';

const Breadcrumbs = ({ entry, ...rest }) => {
  if (isNil(entry) || isEmpty(entry.ancestors)) {
    return null;
  }

  return (
    <nav data-testid="breadcrumbs">
      <Flex py={1} as="ul" alignItems="center" flexWrap="wrap" {...rest}>
        <li>
          <Breadcrumb
            ancestor={{
              title: 'Home',
              url: navigation.HOME,
            }}
          />
        </li>

        {map(entry.ancestors, (ancestor, index) => (
          <li key={camelCase(`${ancestor.url}${index}`)}>
            <Breadcrumb ancestor={ancestor} />
          </li>
        ))}
        <li>
          <P color="grey300" variant={4}>
            {entry.title}
          </P>
        </li>
      </Flex>
    </nav>
  );
};

Breadcrumbs.propTypes = {
  entry: breadcrumbPropTypes,
  pb: systemPropType,
};

Breadcrumbs.defaultProps = {
  entry: null,
  pb: 1,
};

export { Breadcrumbs };
