import { values } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { BLOCK_BACKGROUND_PATTERN_COLOR, BLOCK_THEME_COLOR } from './content-image-block/constants';
import { Box, Col } from '../grid';

const ContentCol = styled(Col)`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const ContentLayout = ({
  showBackgroundPattern,
  children,
  contentPosition,
  hasImage,
  isPageHeader,
  themeColor,
  ...props
}) => {
  // default content layout is right as image is first (left) in markup. Page header has 5 cols for content above lg
  let contentWidth = isPageHeader ? { xs: 12 / 12, md: 8 / 12, lg: 5 / 12 } : { xs: 12 / 12, md: 8 / 12, lg: 4 / 12 };

  // if there is no image to fill first 6 cols add more offset above lg
  let contentOffset = hasImage ? [0, 0, 2 / 12, 1 / 12] : [0, 0, 2 / 12, 7 / 12];

  // if the content position is left, page header has no offset above lg and all others have 1 col offset
  if (contentPosition === 'left') {
    contentOffset = isPageHeader ? [0, 0, 2 / 12, 0] : [0, 0, 2 / 12, 1 / 12];
  }

  // if content position is center (which will only work with no image)
  if (contentPosition === 'center' && !hasImage) {
    contentOffset = [0, 0, 2 / 12, 3 / 12];
    contentWidth = { xs: 12 / 12, md: 8 / 12, lg: 6 / 12 };
  }

  return (
    <ContentCol
      offset={!showBackgroundPattern ? contentOffset : null}
      width={showBackgroundPattern ? { _: '100%', lg: '50%' } : contentWidth}
      textAlign="center"
      py={3}
      position="relative"
      {...props}
    >
      {showBackgroundPattern && themeColor && <BackgroundPattern themeColor={themeColor} />}
      <Box position="static" zIndex={1} pb={showBackgroundPattern && 4} px={showBackgroundPattern && 5}>
        {children}
      </Box>
    </ContentCol>
  );
};

const BackgroundPattern = ({ themeColor }) => {
  const patternColor = BLOCK_BACKGROUND_PATTERN_COLOR[themeColor];
  return (
    <StyledSvg viewBox="0 0 720 926" preserveAspectRatio="none" aria-hidden>
      <path d="M74 860L720 832V932H74V860Z" fill={patternColor.bottomCentre} />
      <path d="M0 0H60V259.5L0 510.5V0Z" fill={patternColor.leftTop} />
      <path d="M0 509L60 257L80 932H0V509Z" fill={patternColor.leftBottom} />
      <path d="M720 933H563C736.5 527 706.5 61 617 -1H720V933Z" fill={patternColor.right} />
      <path d="M720 932H563C580 888.5 588.733 866.732 599.5 837.5L720 833V932Z" fill={patternColor.bottomRight} />
      <path d="M0 862L78 860L80 932H0V862Z" fill={patternColor.bottomLeft} />
    </StyledSvg>
  );
};

BackgroundPattern.propTypes = {
  themeColor: PropTypes.oneOf(values(BLOCK_THEME_COLOR)).isRequired,
};

const StyledSvg = styled.svg`
  width: 100%;
  height: 100%;
  position: absolute;
`;

ContentLayout.propTypes = {
  showBackgroundPattern: PropTypes.bool,
  children: PropTypes.node.isRequired,
  contentPosition: PropTypes.oneOf(['left', 'right', 'center']).isRequired,
  hasImage: PropTypes.bool,
  isPageHeader: PropTypes.bool,
  themeColor: PropTypes.oneOf(values(BLOCK_THEME_COLOR)),
};

ContentLayout.defaultProps = {
  showBackgroundPattern: false,
  hasImage: false,
  isPageHeader: false,
  themeColor: null,
};

export { ContentLayout };
