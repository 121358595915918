import { CMS_BLOCK_PATTERN_STYLE, CMS_BLOCK_THEME_COLOR } from '@nandosaus/constants';
import { propType as StyledSystemPropType } from '@styled-system/prop-types';
import { values } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { BLOCK_PATTERN_COLOR } from '../../constants';
import { Box } from '../../../../grid';

const { TRIANGLE, PEPPER, FLAME } = CMS_BLOCK_PATTERN_STYLE;

const PositionedPattern = ({ children, contentPosition, height, width }) => {
  const layoutProps = {
    left: { transform: 'translateX(50%) translateY(-25%)', right: 0 },
    right: { transform: 'translateX(-50%) translateY(-25%)', left: 0 },
  };

  return (
    <Box position="absolute" zIndex={2} {...layoutProps[contentPosition]} width={width} height={height}>
      {children}
    </Box>
  );
};

PositionedPattern.propTypes = {
  children: PropTypes.node.isRequired,
  contentPosition: PropTypes.oneOf(['left', 'right']).isRequired,
  height: StyledSystemPropType,
  width: StyledSystemPropType.isRequired,
};

PositionedPattern.defaultProps = {
  height: 'auto',
};

const SmallPattern = ({ contentPosition, patternStyle, themeColor }) => (
  <>
    {patternStyle === TRIANGLE && (
      <PositionedPattern
        contentPosition={contentPosition}
        patternStyle={patternStyle}
        width={{ _: '74px', sm: '110px', lg: '147px' }}
      >
        <StyledSvg viewBox="0 0 147 95" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M70.249 47.277.171 94.267V.288l70.078 46.99ZM147 47.277l-70.078 46.99V.288L147 47.276Z"
            fill={BLOCK_PATTERN_COLOR[themeColor].tertiary}
          />
        </StyledSvg>
      </PositionedPattern>
    )}
    {patternStyle === PEPPER && (
      <PositionedPattern
        contentPosition={contentPosition}
        patternStyle={patternStyle}
        width={{ _: '40px', sm: '60px', lg: '80px' }}
      >
        <StyledSvg viewBox="0 0 80 146" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M39.83 69.682.012 0h79.636L39.83 69.682ZM39.83 145.171.012 75.489h79.636L39.83 145.171Z"
            fill={BLOCK_PATTERN_COLOR[themeColor].tertiary}
          />
        </StyledSvg>
      </PositionedPattern>
    )}
    {patternStyle === FLAME && (
      <PositionedPattern
        contentPosition={contentPosition}
        patternStyle={patternStyle}
        width={{ _: '35px', sm: '53px', lg: '70px' }}
      >
        <StyledSvg viewBox="0 0 70 124" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M58.025 5.302c1.2.054 14.724-.89 10.378 28.278-2.605 14.957-15.36 45.224-24.719 67.431-5.415 12.842-9.417 22.287-9.417 22.287L4.683 41.952S-9.873 6.156 12.867.555c3.978-.407 21.25-3.61 23.023 40.802 0 0 2.73-13.601 3.922-18.056 1.676-6.175 6.065-18.686 18.213-17.999Z"
            fill={BLOCK_PATTERN_COLOR[themeColor].tertiary}
          />
        </StyledSvg>
      </PositionedPattern>
    )}
  </>
);

SmallPattern.propTypes = {
  contentPosition: PropTypes.oneOf(['left', 'right']),
  patternStyle: PropTypes.oneOf(values(CMS_BLOCK_PATTERN_STYLE)).isRequired,
  themeColor: PropTypes.oneOf(values(CMS_BLOCK_THEME_COLOR)).isRequired,
};

SmallPattern.defaultProps = {
  contentPosition: 'left',
};

const Pattern = ({ contentPosition, patternStyle, themeColor }) => {
  const patternWidth = {
    [TRIANGLE]: { _: '72px', sm: '111px', lg: '148px' },
    [PEPPER]: { _: '69px', sm: '103px', lg: '137px' },
    [FLAME]: { _: '73px', sm: '110px', lg: '146px' },
  };
  return (
    <Box
      height={{ _: '50%', lg: '100%' }}
      position="absolute"
      right={contentPosition === 'right' ? 0 : undefined}
      transform={contentPosition === 'right' ? 'scaleX(-1)' : undefined}
      top={{ _: '50%', lg: 0 }}
      width={patternWidth[patternStyle]}
    >
      {patternStyle === TRIANGLE && (
        <StyledSvg viewBox="0 0 148 755" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M-.506 0 0 744l99-370.224L-.506 0Z" fill={BLOCK_PATTERN_COLOR[themeColor].primary} />
          <path
            d="m24.615 424.738-13.76 6.106-13.644 6.149-.079.036-18.807 49.733-49.81-18.809-.08.036-13.609 6.228-13.723 6.185 6.122 13.744 6.165 13.629.035.079 49.81 18.809-18.771 49.812 6.28 13.672 6.2 13.709 13.723-6.185 13.76-6.106 18.85-49.847 49.81 18.808 13.724-6.185 13.688-6.263-6.2-13.708-6.166-13.63.08-.036-.115-.043-49.81-18.809 18.807-49.733-.036-.079-6.165-13.629-6.28-13.673ZM38.395 748.441l13.723-6.185 18.886-49.768 49.811 18.809 13.688-6.264 13.723-6.185-6.2-13.708-6.122-13.744-49.925-18.852 18.886-49.769-6.28-13.673-6.2-13.708-13.724 6.185-13.759 6.106-18.85 49.847-49.81-18.809-.036-.078.035.078-13.723 6.185-13.688 6.264 6.2 13.708 6.087 13.665 49.924 18.852-18.807 49.734-.079.035.115.043 6.165 13.63 6.2 13.708 13.76-6.106Z"
            fill={BLOCK_PATTERN_COLOR[themeColor].secondary}
          />
        </StyledSvg>
      )}
      {patternStyle === PEPPER && (
        <StyledSvg viewBox="0 0 137 718" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M7.357.734c11.799 6.816 20.577 17.71 24.55 30.47a116.073 116.073 0 0 1 5.448 30.819l.055 7.833c.9 6.992 5.098 13.188 13.046 18.972 2.974 2.163 6.322 4.46 10.676 7.235 1.984.986 3.859 1.947 5.689 2.914 4.82 2.141 20.462 12.506 22.259 13.701l1.036.684-.043.13.678.535a136.359 136.359 0 0 1 13.613 11.49c3.625 3.269 44.757 42.55 28.816 118.215a224.14 224.14 0 0 1-16.792 48.768c-.824 2.495-1.694 5.131-2.453 7.917l-.229.692C76.865 372.041 28.046 524.02 78.332 644.43c.08.244 9.696 25.691-13.487 38.758-6.427 3.631-14.887 4.061-25.202 1.237a130.648 130.648 0 0 1-33.07-16.191l-7.454-5.466c-15.464-10.287-40.214-29.464-69.668-62.309a365.924 365.924 0 0 1-30.168-37.549l-4.246-6.18c-4.275-6.095-8.083-11.788-10.531-16.162a278.466 278.466 0 0 1-24.555-47.656l-.191-.519c-1.253-3.461-2.917-7.358-4.466-11.186-2.372-5.768-4.604-11.252-5.478-14.401a356.583 356.583 0 0 1-17.525-68.335l-4.607-38.646c-4.96-35.986-.966-88.543-.899-89.101 4.165-42.872 13.076-78.839 26.729-107.406l.234-.71c8.836-21.02 23.008-39.428 41.204-53.519 18.197-14.09 39.83-23.41 62.896-27.094a260.298 260.298 0 0 0 16.35-5.776c9.784-3.923 15.694-9.423 17.71-17.247.468-1.663.746-3.376.83-5.105.052-.36.065-.723.038-1.086v-.519c.737-9.457.296-27.555-11.129-42.468l-.266-.326-.152-.382a4.654 4.654 0 0 1-.508-2.243c.023-.774.241-1.527.636-2.192.326-.46.753-.843 1.251-1.121a3.949 3.949 0 0 1 1.632-.487L4.138.283c.96-.103 1.935.02 2.844.358l.375.093Z"
            fill={BLOCK_PATTERN_COLOR[themeColor].primary}
          />
          <path
            d="M98.12 571.628s-25.343 37.261-12.247 69.216c0 0 2.42 6.286 5.008 14.788l4.275 13.627c6.611 22.693-3.875 48.175-3.875 48.175s20.957-5.85 32.223-41.043l.359-.83c3.403-8.122 18.674-51.456-21.58-99.194a41.589 41.589 0 0 0-4.164-4.739Zm6.206-113.966a1.13 1.13 0 0 0-1.246.493 118.97 118.97 0 0 1-34.007 37.528C4.13 540.289 28.067 613.827 36.35 634.143c1.143 2.913 2.17 2.549 2.17-.445.28-28.299 16.314-51.718 29.594-66.996l2.942-2.819c36.258-36.055 34.808-85.007 34.115-105.181a1.132 1.132 0 0 0-.844-1.04Z"
            fill={BLOCK_PATTERN_COLOR[themeColor].secondary}
          />
        </StyledSvg>
      )}
      {patternStyle === FLAME && (
        <StyledSvg viewBox="0 0 146 796" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M63.882 107.768c46.729-31.025 53.791-84.149 56.593-105.761a1.188 1.188 0 0 0-2.097-.922A125.33 125.33 0 0 1 76.83 34.284C1.803 69.432 14.348 149.743 19.505 172.192c.7 3.091 1.788 3.031 2.322-.108 5.062-29.28 25.644-50.846 42.055-64.316Zm-220.719 671.758a3.347 3.347 0 0 0-1.474 3.77 3.352 3.352 0 0 0 3.232 2.438c246.796 3.34 380.064-200.078 249.797-423.97-25.113-43.302-58.087-82.663-94.782-135.975-21.009-30.55-53.97-123.475.15-176.55a1.586 1.586 0 0 0-1.458-2.644C-159.366 79.587-216.955 204.644-214.965 309.11c1.066 56.9 12.647 103.632 27.092 130.273a1.268 1.268 0 0 1-.146 1.441 1.262 1.262 0 0 1-1.404.355c-41.259-15.103-67.06-58.857-73.776-71.487a1.943 1.943 0 0 0-1.837-1.194 1.95 1.95 0 0 0-1.784 1.273c-5.532 11.642-21.64 51.881-15.627 115.347 10.845 114.697 68.812 142.702 130.415 197.374 54.841 48.653 17.85 82.749-4.805 97.034Z"
            fill={BLOCK_PATTERN_COLOR[themeColor].primary}
          />
          <path
            d="M-172.999 803 3.105 611.673l88.123 189.77L-173 803ZM111.626 787.389c-.67-2.584.706-6.336 2.224-8.318 1.376-1.805 3.67-3.363 5.964-4.071 4.764-2.407 11.293-1.876 14.505 2.903 2.364 3.504 3.352 7.15 2.223 11.221-1.024 3.823-3.565 7.08-7.305 8.602-3.177 1.309-6.847 1.239-9.988-.142-1.976-.849-3.564-2.195-4.976-3.823-1.659-1.947-2.047-4-2.647-6.407v.035ZM97.475 727.389c2.929-2.654 7.905-3.044 11.399-1.522 4.588 2.018 7.799 6.089 8.399 11.151a11.7 11.7 0 0 1 0 2.23c.353 1.982.177 4.071-.741 6.018-1.976 4.177-5.682 7.362-10.411 7.823-4.411.424-9.352-1.381-12.034-5.027-2.294-3.08-3.318-6.938-2.647-10.726.74-4.106 3.035-7.185 6.035-9.911v-.036ZM51.702 645.124c-.53-4.531 1.094-9.416 4.905-12.177 3.847-2.796 9.14-3.681 13.588-1.664 8.822 4.036 11.01 15.045 5.223 22.62-3.635 4.743-11.258 6.124-15.987 2.194-.424-.354-.777-.743-1.13-1.132-3.529-1.983-6.176-5.806-6.6-9.806v-.035ZM73.194 696.345c-1.624-4.177-1.236-9.522 1.8-12.991a13.502 13.502 0 0 1 6.07-3.929c.211-.071.388-.212.564-.283 2.788-.991 6-1.274 8.752 0 2.541 1.168 5.365 3.363 6.141 6.195 1.094 3.964 1.483 7.61-.176 11.539-2.541 6.018-9.458 9.487-15.705 6.832-3.317-1.416-6.105-3.964-7.446-7.398v.035ZM35.467 590.044c1.376-2.726 3.247-4.956 6.176-6.195 2.576-1.062 6.14-1.203 8.717 0 1.694.779 3.176 2.054 4.376 3.54a12.942 12.942 0 0 1 2.682 3.363c1.624 2.797 2.259 5.876 1.73 9.097-.918 5.877-6.706 10.974-12.847 9.77-4.482-.885-7.87-3.15-10.163-7.079-2.118-3.646-2.683-8.602-.671-12.496Z"
            fill={BLOCK_PATTERN_COLOR[themeColor].secondary}
          />
        </StyledSvg>
      )}
    </Box>
  );
};

Pattern.propTypes = {
  contentPosition: PropTypes.oneOf(['left', 'right']),
  patternStyle: PropTypes.oneOf(values(CMS_BLOCK_PATTERN_STYLE)).isRequired,
  themeColor: PropTypes.oneOf(values(CMS_BLOCK_THEME_COLOR)).isRequired,
};

Pattern.defaultProps = {
  contentPosition: 'left',
};

const Slant = () => (
  <Box position="absolute" bottom="-1px" right="-1px" width="100%" zIndex={1}>
    <StyledSvg viewBox="0 0 1440 77" preserveAspectRatio="none" aria-hidden>
      <path d="M0 77L1440 0V77H0Z" fill="currentColor" />
    </StyledSvg>
  </Box>
);

const StyledSvg = styled.svg`
  width: 100%;
  height: auto;
`;

export { Pattern, Slant, SmallPattern };
