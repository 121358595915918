import { background, layout, position } from 'styled-system';
import { ANALYTICS_EVENTS, colors } from '@nandosaus/constants';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import styled, { css } from 'styled-components';
import StyledSystemPropTypes, { propType as StyledSystemPropType } from '@styled-system/prop-types';

import { useBrazeImpression } from '@hooks/use-braze-impression';
import { analytics } from '../../../analytics';
import { assetQuery, buttonQuery, matrixBlockQuery } from '../../../content/query-snippets';
import { BackgroundImage, Body, overlayStyles, wrapperStyles } from '../styles';
import { Box, Flex } from '../../grid';
import { breadcrumbPropTypes, cmsImagePropTypes } from '../../../prop-types';
import { Breadcrumbs } from '../../breadcrumbs';
import { Button } from '../../button';
import { buttonTransform, imageTransform } from '../../../content/transforms';
import { contentImageDefaultProps, contentImagePropTypes } from '../prop-types';
import { ContentImageLayout } from '../content-image-layout';
import { HeadingWithLineHighlights } from '../../heading-with-line-highlights';
import { Image } from '../../image';
import { P, RichText } from '../../typography';

const PageHeaderBlock = ({
  backgroundColor,
  backgroundImage,
  hasBlackOverlayImage,
  body,
  breadcrumbs,
  button,
  contentPosition,
  heading,
  image,
  leftDecal,
  leftDecalStyle,
  logo,
  rightDecal,
  rightDecalStyle,
  showBreadcrumbs,
  showOverlay,
  stackOnMobile,
  subheading,
  hasHeadingHighlight,
  py,
  brazeLogImpression,
}) => {
  const transformedImage = imageTransform(image);
  const transformedBackgroundImage = imageTransform(backgroundImage);
  const transformedButton = buttonTransform(button);

  const hasImage = !isEmpty(transformedImage);
  const hasBackgroundImage = !isEmpty(transformedBackgroundImage);
  const hasLogo = !isEmpty(logo);
  const isImageOnly = !heading && !subheading;
  const backgroundImg = hasBackgroundImage && (
    <BackgroundImage
      alt={transformedBackgroundImage.alt}
      isBackground
      height={stackOnMobile ? '50vh' : undefined}
      focalPoint={transformedBackgroundImage.focalPoint}
      src={transformedBackgroundImage.url}
      srcSet={transformedBackgroundImage.srcset}
      stackOnMobile={stackOnMobile && !hasImage}
    />
  );

  useBrazeImpression({ brazeLogImpression });

  return (
    <Wrapper
      backgroundColor={backgroundColor}
      showOverlay={showOverlay}
      showOverlayOnMobile={!stackOnMobile || hasImage}
      px={{ xs: '3rem', md: '0' }}
      height={isImageOnly && { xs: '293px', md: '507px' }}
    >
      {showBreadcrumbs && (
        <Flex p={1} zIndex={5} position={stackOnMobile ? { xs: 'relative', lg: 'absolute' } : { xs: 'absolute' }}>
          <Breadcrumbs entry={breadcrumbs} />
        </Flex>
      )}

      {!hasBackgroundImage &&
        !isEmpty(leftDecal) &&
        (leftDecal instanceof Array ? (
          <Decal
            backgroundPosition={hasImage ? '0% 10%' : { xs: '0% 26%', sm: '0% 22%', md: '0% 18%' }}
            backgroundSize={hasImage ? { xs: 'contain', md: 'auto' } : { xs: '6rem', sm: '7.5rem', md: '9rem' }}
            left="0"
            {...imageTransform(leftDecal)}
            {...leftDecalStyle}
          />
        ) : (
          <Box
            top={3}
            left="0"
            display={hasImage || hasLogo ? { xs: 'none', lg: 'block' } : 'block'}
            width="15%"
            height="55%"
            position="absolute"
            {...leftDecalStyle}
          >
            {leftDecal}
          </Box>
        ))}

      {backgroundImg}

      {hasBlackOverlayImage && (
        <Box
          zIndex="1"
          position="absolute"
          width="100%"
          height="100%"
          opacity="30%"
          backgroundColor={colors.black}
          style={{ pointerEvents: 'none' }}
        />
      )}

      <ContentImageLayout
        contentAlignment="center"
        contentPosition={contentPosition}
        image={transformedImage}
        isPageHeader
        py={py}
      >
        {!isEmpty(logo) && <Image {...logo} />}

        {!!heading && (
          <HeadingWithLineHighlights
            {...{
              highlightBackgroundColor: hasHeadingHighlight ? colors.yellow400 : null,
              heading,
              overrideHeadingHighlighting: ['alwaysHighlightFirstLine'],
            }}
          />
        )}
        {!!subheading &&
          (typeof subheading === 'string' ? (
            <P variant={1} mt={2} pt={{ xs: 1, sm: 3, md: 0 }} keepMargins>
              {subheading}
            </P>
          ) : (
            subheading
          ))}
        {!!body && (
          <Body>
            <RichText keepMargins>{body}</RichText>
          </Body>
        )}

        {!isEmpty(transformedButton) && (
          <Button
            mt={2}
            {...transformedButton}
            onClick={() =>
              analytics.track(ANALYTICS_EVENTS.PAGE_HEADER_BLOCK_CLICKED, {
                text: heading,
              })
            }
          >
            {transformedButton.text}
          </Button>
        )}
      </ContentImageLayout>

      {!hasBackgroundImage &&
        !isEmpty(rightDecal) &&
        (rightDecal instanceof Array ? (
          <Decal
            backgroundPosition={
              hasImage ? { xs: '-20% -10%', md: '100% 12%' } : { xs: '100% 24%', sm: '100% 18%', md: '100% 12%' }
            }
            backgroundSize={hasImage ? { xs: 'contain', md: 'auto' } : { xs: '6rem', sm: '7.5rem', md: '9rem' }}
            right="0"
            width={hasImage ? { xs: '33vw', md: '100%' } : '100%'}
            {...imageTransform(rightDecal)}
            {...rightDecalStyle}
          />
        ) : (
          <Box
            top={3}
            right="0"
            display={hasImage || hasLogo ? { xs: 'none', lg: 'block' } : 'block'}
            width="15%"
            height="55%"
            position="absolute"
            {...rightDecalStyle}
          >
            {rightDecal}
          </Box>
        ))}
    </Wrapper>
  );
};

const Wrapper = styled(Flex)`
  ${wrapperStyles}
  ${overlayStyles}
`;

const Decal = styled.div`
  ${background}
  ${layout}
  ${position}
  background-image: ${({ url }) => css`url(${url})`};
  background-repeat: no-repeat;
  background-size: auto;
  bottom: 0;
  display: block;
  height: 100%;
  position: absolute;
  top: 0;
  width: 100%;
`;

PageHeaderBlock.query = `
  ... on contentMatrix_pageHeaderBlock_BlockType {
    ${matrixBlockQuery}
    ${assetQuery('backgroundImage')}
    ${assetQuery('image')}
    ${assetQuery('leftDecal')}
    ${assetQuery('rightDecal')}
    ${buttonQuery}
    backgroundColor
    body
    contentPosition
    heading
    showBreadcrumbs
    showOverlay
    stackOnMobile
    subheading
    brazeId
  }
`;

PageHeaderBlock.propTypes = {
  backgroundColor: contentImagePropTypes.backgroundColor,
  backgroundImage: contentImagePropTypes.backgroundImage,
  hasBlackOverlayImage: PropTypes.bool,
  body: contentImagePropTypes.body,
  breadcrumbs: breadcrumbPropTypes,
  button: contentImagePropTypes.button,
  contentPosition: contentImagePropTypes.contentPosition,
  heading: contentImagePropTypes.heading,
  image: contentImagePropTypes.image,
  leftDecal: PropTypes.oneOfType([PropTypes.element, cmsImagePropTypes]),
  rightDecal: PropTypes.oneOfType([PropTypes.element, cmsImagePropTypes]),
  leftDecalStyle: StyledSystemPropType,
  rightDecalStyle: StyledSystemPropType,
  logo: PropTypes.shape({
    alt: PropTypes.string,
    src: PropTypes.string.isRequired,
    ...StyledSystemPropTypes.space,
  }),
  showBreadcrumbs: PropTypes.bool,
  showOverlay: contentImagePropTypes.showOverlay,
  stackOnMobile: contentImagePropTypes.stackOnMobile,
  subheading: PropTypes.node,
  hasHeadingHighlight: PropTypes.bool,
  py: StyledSystemPropType,
  brazeLogImpression: PropTypes.func,
};

PageHeaderBlock.defaultProps = {
  backgroundColor: 'dark',
  backgroundImage: contentImageDefaultProps.backgroundImage,
  hasBlackOverlayImage: false,
  body: contentImageDefaultProps.body,
  breadcrumbs: undefined,
  button: contentImageDefaultProps.button,
  contentPosition: contentImageDefaultProps.contentPosition,
  heading: contentImageDefaultProps.heading,
  image: contentImageDefaultProps.image,
  leftDecal: null,
  leftDecalStyle: null,
  logo: null,
  rightDecal: null,
  rightDecalStyle: null,
  showBreadcrumbs: false,
  showOverlay: contentImageDefaultProps.showOverlay,
  stackOnMobile: contentImageDefaultProps.stackOnMobile,
  subheading: null,
  hasHeadingHighlight: false,
  py: undefined,
  brazeLogImpression: null,
};

export { PageHeaderBlock };
