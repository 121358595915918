const matrixBlockQuery = `
  typeHandle
  id
`;

const assetQuery = name => `
  ${name} {
    url
    lo: url @transform(handle: "block1x")
    hi: url @transform(handle: "block2x")
    focalPoint
    ...on assets_Asset {
      alt
    }
  }
`;

const perksShopProductsQuery = `
  query perksShopProductData {
    perksShopProductData {
      perksShopProducts {
        id
        pointsPrice
        description
        image {
          alt
          focalPoint
          hi
          lo
          url
        }
        perksShopProductCategory {
          title
        }
        title
        variants {
          id
          plu
          stock
          color
          size
        }
      }
      categories {
        title
      }
    }
  }
`;

const buttonQuery = `
  button: children {
    ... on contentMatrix_button_BlockType {
      buttonText
      link: children {
        ... on contentMatrix_internalLink_BlockType {
          typeHandle
          internalLink {
            uri
          }
        },
        ... on contentMatrix_externalLink_BlockType {
          typeHandle
          externalLink
          openLinkInNewWindow
        }
      }
    }
  }
`;

const tiltedTagQuery = `
  tiltedTag: children {
    ... on contentMatrix_tiltedTag_BlockType {
      tagText
    }
  }
`;

// TODO: align item queries to be entries not child blocks
const itemsQuery = (field, block) => {
  const fields = {
    accordionItem: `
      ${field}: children {
        ... on contentMatrix_${block}_BlockType {
          blockTitle
          defaultExpanded
          body
        }
      }
    `,
    linkCardBlock: `
      ${field} {
        ... on linkCardItems_linkCardItems_Entry {
          ${assetQuery('image')}
          externalLink
          heading
        }
      }
    `,
    menuCarouselItem: `
      ${field}: children {
        ... on contentMatrix_${block}_BlockType {
          ${assetQuery('image')}
          heading
          genericLink
        }
      }
    `,
    cardFeatureBlock: `
      ${field} {
        ... on featureCardItems_featureCardItems_Entry {
          ${assetQuery('image')}
          slug
          title
          heading
          description
        }
      }
    `,
    perksShopHighlightReelBlock: `
      ${field} {
        ... on perksShopProducts_perksShopProduct_Entry {
          id
        }
      }
    `,
    valuesItem: `
      ${field}: children {
          ... on contentMatrix_${block}_BlockType {
          heading
          description
          ${assetQuery('image')}
        }
      }
    `,
    careerOptionsItem: `
      ${field}: children {
        ... on contentMatrix_${block}_BlockType {
          heading
          description
          tagText
          ${assetQuery('image')}
          ${buttonQuery}
        }
      }
    `,
  };

  return fields[block];
};

const accordionItemsQuery = itemsQuery('items', 'accordionItem');

const linkCardItemsQuery = itemsQuery('linkCardItems', 'linkCardBlock');

const menuCarouselItemsQuery = itemsQuery('items', 'menuCarouselItem');

const valuesItemsQuery = itemsQuery('items', 'valuesItem');

const careerOptionsItemsQuery = itemsQuery('items', 'careerOptionsItem');

const recipeEntryQuery = `
  ... on recipes_recipe_Entry {
    cookTime
    image: ${assetQuery('gridImage')}
    prepTime
    slug
    title
    typeHandle
    uri
  }
`;

const productEntryQuery = `
  ... on products_product_Entry {
    image: ${assetQuery('gridImage')}
    slug
    title
    typeHandle
    uri
  }
`;

const newsEntryQuery = `
  ... on news_news_Entry {
    date
    image: ${assetQuery('gridImage')}
    slug
    title
    typeHandle
    uri
  }
`;

const relatedEntriesQuery = `
  relatedEntries {
    ${newsEntryQuery}
    ${productEntryQuery}
    ${recipeEntryQuery}
  }
`;

const mosaicBlockQuery = `
  mosaicEntries {
    ${newsEntryQuery}
    ${productEntryQuery}
    ${recipeEntryQuery}
  }
`;

const floodlightQuery = `
  floodlightPageLoadEvent {
    ... on floodlightPageLoadEvent_BlockType {
      activityGroupTagString
      activityTagString
      countingMethod
    }
  }
`;

const seomaticQuery = uri => `seomatic (siteId: ${process.env.cms.siteId}, uri: "${uri}", asArray: true) {
  metaTitleContainer
  metaTagContainer
  metaLinkContainer
  metaJsonLdContainer
}`;

// seomatic can 'piggyback' on another query to grab relevant information without uri https://nystudio107.com/docs/seomatic/advanced.html#piggybacking-graphql-queries
const seomaticChildQuery = `seomatic (siteId: ${process.env.cms.siteId}, asArray: true) {
  metaTitleContainer
  metaTagContainer
  metaLinkContainer
  metaJsonLdContainer
}`;

const categoriesQuery = categoryType => `categories(group: ["${categoryType}"]){
  title
}`;

const perksShopHighlightReelItemsQuery = itemsQuery('perksShopHighlightReelItems', 'perksShopHighlightReelBlock');

export {
  accordionItemsQuery,
  assetQuery,
  buttonQuery,
  categoriesQuery,
  careerOptionsItemsQuery,
  floodlightQuery,
  itemsQuery,
  linkCardItemsQuery,
  matrixBlockQuery,
  menuCarouselItemsQuery,
  mosaicBlockQuery,
  newsEntryQuery,
  perksShopHighlightReelItemsQuery,
  perksShopProductsQuery,
  productEntryQuery,
  recipeEntryQuery,
  relatedEntriesQuery,
  seomaticQuery,
  seomaticChildQuery,
  tiltedTagQuery,
  valuesItemsQuery,
};
