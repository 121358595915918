import { colors } from '@nandosaus/constants';
import { find, get, includes, isEmpty, values } from 'lodash';
import React from 'react';
import styled from 'styled-components';

import { useBrazeImpression } from '@hooks/use-braze-impression';
import { assetQuery, buttonQuery, matrixBlockQuery, tiltedTagQuery } from '../../../content/query-snippets';
import { BLOCK_THEME_COLOR } from './constants';
import { Body, wrapperStyles } from '../styles';
import { Button } from '../../button';
import { buttonTransform, imageTransform } from '../../../content/transforms';
import { contentImageDefaultProps, contentImagePropTypes } from '../prop-types';
import { ContentImageLayout } from '../content-image-layout';
import { Flex } from '../../grid';
import { HeadingWithLineHighlights } from '../../heading-with-line-highlights';
import { Pattern } from './components/patterns';
import { RichText } from '../../typography';
import { TiltedTag } from '../../tilted-tag';

const { ORANGE, GREEN, PINK } = BLOCK_THEME_COLOR;

const HEADING_HIGHLIGHT_COLOR = {
  light: {
    [ORANGE]: colors.orange400,
    [GREEN]: colors.green300,
    [PINK]: colors.pink200,
  },
  dark: {
    [ORANGE]: colors.orange600,
    [GREEN]: colors.green600,
    [PINK]: colors.neonPink,
  },
};

const BLOCK_MIN_HEIGHT = '35vh';

const BLOCK_HORIZONTAL_SPACING = '3rem';

const getBrazeButtonProps = ({ brazeLogClick, buttonLink, text }) => {
  if (!brazeLogClick || !buttonLink) return undefined;

  return {
    text,
    onClick: () => {
      brazeLogClick();
    },
    href: buttonLink,
  };
};

const ContentImageBlock = ({
  backgroundColor,
  body,
  button,
  children,
  contentAlignment,
  contentPosition,
  heading,
  image,
  patternStyle,
  shouldFlipContentPositionMobile,
  shouldHideImageOnMobile,
  tiltedTag,
  themeColor,
  overrideHeadingHighlighting,
  brazeLogClick,
  buttonLink,
  brazeLogImpression,
}) => {
  useBrazeImpression({ brazeLogImpression });

  const transformedImage = imageTransform(image);
  const transformedButtonProps = buttonTransform(button);
  const brazeButtonProps = getBrazeButtonProps({
    buttonLink,
    brazeLogClick,
    text: transformedButtonProps?.text || button,
  });
  const buttonProps = brazeButtonProps || transformedButtonProps;

  const tagContent = get(
    find(tiltedTag, ({ tagText }) => !isEmpty(tagText)),
    'tagText'
  );

  const showBackgroundPattern = backgroundColor === 'dark';

  const hasValidThemeColor = includes(values(BLOCK_THEME_COLOR), themeColor);

  return (
    <Wrapper
      showBackgroundPattern={showBackgroundPattern}
      py={showBackgroundPattern ? BLOCK_HORIZONTAL_SPACING : 0}
      minHeight={BLOCK_MIN_HEIGHT}
      position="relative"
    >
      {!showBackgroundPattern && patternStyle && hasValidThemeColor && (
        <Pattern themeColor={themeColor} contentPosition={contentPosition} patternStyle={patternStyle} />
      )}
      <ContentImageLayout
        showBackgroundPattern={showBackgroundPattern}
        contentPosition={contentPosition}
        image={transformedImage}
        flexDirection={shouldFlipContentPositionMobile ? { xs: 'column-reverse', lg: 'row' } : ''}
        py={!showBackgroundPattern ? BLOCK_HORIZONTAL_SPACING : null}
        shouldHideImageOnMobile={shouldHideImageOnMobile}
        themeColor={hasValidThemeColor ? themeColor : null}
        patternStyle={patternStyle}
      >
        <HeadingWithLineHighlights
          {...{
            heading,
            color: showBackgroundPattern ? colors.white : colors.black,
            highlightBackgroundColor: HEADING_HIGHLIGHT_COLOR[backgroundColor][themeColor],
            overrideHeadingHighlighting,
          }}
        />

        {!!body && (
          <Body textAlign={contentAlignment}>
            <RichText keepMargins>{body}</RichText>
          </Body>
        )}
        {!isEmpty(buttonProps) && (
          <ButtonContainer>
            <Button
              mb={{ xs: shouldHideImageOnMobile ? 0 : '4rem', lg: '4rem' }}
              mt="2rem"
              themeColor={hasValidThemeColor && showBackgroundPattern ? themeColor : null}
              variant={showBackgroundPattern ? 'secondary' : 'primary'}
              onClick={buttonProps?.onClick}
              href={buttonProps?.href}
              target={buttonProps?.target}
            >
              {buttonProps.text}
            </Button>
            {!!tagContent && (
              <TagContainer display={{ xs: 'none', sm: 'block' }} ml={{ sm: '0.5rem', xl: '0' }}>
                <TiltedTag arrowPosition="top">{tagContent}</TiltedTag>
              </TagContainer>
            )}
          </ButtonContainer>
        )}
        {children}
      </ContentImageLayout>
    </Wrapper>
  );
};

const Wrapper = styled(Flex)`
  ${wrapperStyles}
`;

const ButtonContainer = styled.div`
  position: relative;
`;

const TagContainer = styled(Flex)`
  position: absolute;
  top: 52%;
  left: 110%;
  width: fit-content;
`;

ContentImageBlock.query = `
  ... on contentMatrix_contentImageBlock_BlockType {
    ${matrixBlockQuery}
    backgroundColor
    body
    ${buttonQuery}
    contentPosition
    contentAlignment
    heading
    ${assetQuery('image')}
    overrideHeadingHighlighting
    shouldFlipContentPositionMobile
    shouldHideImageOnMobile
    ${tiltedTagQuery}
    themeColor
    patternStyle
    brazeId
  }
`;

ContentImageBlock.propTypes = contentImagePropTypes;
ContentImageBlock.defaultProps = contentImageDefaultProps;

export { ContentImageBlock };
