import { propType as systemPropType } from '@styled-system/prop-types';
import styled from 'styled-components';

import { Box } from '../grid';

const StyledSvg = styled.svg`
  width: 100%;
  height: 100%;
`;

export const DiagonalVertical = props => {
  return (
    <Box height="100%" {...props}>
      <StyledSvg fill="currentColor" viewBox="0 0 100 100" preserveAspectRatio="none" aria-hidden>
        <polygon points="50,0 100,0 50,100 0,100" />
      </StyledSvg>
    </Box>
  );
};

DiagonalVertical.propTypes = {
  width: systemPropType,
};

DiagonalVertical.defaultProps = {
  width: '60px',
};
