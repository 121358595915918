import { typography } from 'styled-system';
import styled, { css } from 'styled-components';

import { breakpoints } from '../../constants';
import { Container, Row } from '../grid';
import { Image } from '../image';

// need to define StyledRow here so it can be referenced in wrapper below
const StyledRow = styled(Row)`
  flex-wrap: wrap;
  height: 100%;

  @media (min-width: ${breakpoints.lg}) {
    flex-wrap: nowrap;
  }

  ${({ showBackgroundPattern, theme }) =>
    showBackgroundPattern
      ? css`
          position: relative;
          background-color: ${theme.colors.black};
          color: ${theme.colors.white};
        `
      : css`
          color: ${theme.colors.black};
        `}
`;

const StyledContainer = styled(Container)`
  flex-grow: 1;
  justify-content: center;
  position: relative;
  z-index: 1;

  ${({ showBackgroundPattern }) =>
    showBackgroundPattern &&
    css`
      padding: 0;
    `}
`;

const wrapperStyles = css`
  overflow: hidden;
  position: relative;
  width: 100%;
  flex-wrap: wrap;

  @media (min-width: ${breakpoints.lg}) {
    flex-wrap: nowrap;
  }
`;

const mediaColStyles = css`
  max-height: 50vh;
  padding: 0;
  position: relative;

  ${({ isPageHeader }) => isPageHeader && 'order: 1;'}

  @media (min-width: ${breakpoints.lg}) {
    height: auto;
    max-height: none;
    order: ${({ contentPosition }) => (contentPosition === 'left' ? '1' : '-1')};
  }

  /* Force the image into a square aspect ratio */
  &::after {
    content: '';
    display: block;
    padding-bottom: 100%;
  }
`;

const overlayStyles = css`
  ${({ showOverlay, showOverlayOnMobile, theme }) =>
    showOverlay &&
    css`
      ${showOverlayOnMobile &&
        css`
          color: ${theme.colors.white};
          p {
            color: ${theme.colors.white};
          }
          svg {
            fill: ${theme.colors.white};
          }
        `}

      &::after {
        background-color: ${theme.colors.black};
        bottom: 0;
        content: none;
        left: 0;
        opacity: 0.4;
        position: absolute;
        right: 0;
        top: 0;
      }

      @media (min-width: ${breakpoints.lg}) {
        color: ${theme.colors.white};
        p {
          color: ${theme.colors.white};
        }

        svg {
          fill: ${theme.colors.white};
        }

        &::after {
          content: '';
        }
      }

      ${showOverlayOnMobile &&
        css`
          &::after {
            content: '';
          }
        `}
    `}
`;

const BackgroundImage = styled(Image)`
  position: absolute;

  ${({ stackOnMobile, height }) =>
    stackOnMobile &&
    `
      height: ${height};
      position: relative;
  `}

  @media (min-width: ${breakpoints.lg}) {
    max-height: 100%;
    height: 100%;
    position: absolute;
  }
`;

const Body = styled.div`
  ${typography}
`;

export { BackgroundImage, Body, mediaColStyles, overlayStyles, StyledContainer, StyledRow, wrapperStyles };
