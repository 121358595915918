import { first, get, isArray, isEmpty, isNil, trimStart } from 'lodash';

const imageTransform = queryResponse => {
  const image = first(queryResponse);

  if (isNil(image)) return null;

  const { lo, hi } = image;

  return { ...image, srcset: `${lo}, ${hi} 2x` };
};

const formatHref = buttonLink => {
  const internalLink = get(first(buttonLink.internalLink), 'uri', null);

  if (buttonLink.typeHandle === 'internalLink' && internalLink) {
    return `/${trimStart(internalLink, '/')}`;
  }

  if (buttonLink.typeHandle === 'externalLink') {
    return buttonLink.externalLink;
  }

  return null;
};

const buttonTransform = rawButton => {
  const isApiButton = isArray(rawButton);
  const button = isApiButton ? first(rawButton) : rawButton;

  if (!button) {
    return null;
  }

  if (!isApiButton) {
    return button;
  }

  if (isEmpty(button.link)) {
    return null;
  }

  const buttonLink = first(button.link);

  return {
    text: button.buttonText,
    href: formatHref(buttonLink),
    target: buttonLink.openLinkInNewWindow ? '_blank' : null,
  };
};

export { imageTransform, buttonTransform };
