import PropTypes from 'prop-types';

const PepperDecal = ({ colorTheme, ...rest }) => {
  return (
    <svg
      width="310"
      height="101"
      viewBox="0 0 310 100"
      fill="none"
      pointerEvents="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path d="M0 54.5156L310 99.5799V100.481H0V54.5156Z" fill={colorTheme.diagonalColor} data-testid="diagonal" />
      <path
        d="M14.1429 29.8173V29.7604C14.1483 29.7418 14.1483 29.722 14.1429 29.7034C14.5029 26.5134 15.5211 23.4314 17.1338 20.65C19.0952 17.7921 21.6756 15.4069 24.6856 13.6692C25.4076 13.1226 26.0493 12.6215 26.6452 12.1091C27.1544 11.738 27.5681 11.2527 27.8529 10.6926C28.1376 10.1326 28.2853 9.51371 28.2839 8.88626C28.2747 8.6322 28.2362 8.38003 28.1693 8.13465C28.1693 8.07771 28.1693 8.03216 28.1693 7.98661V7.9069C27.7073 5.75174 26.4886 3.83027 24.7315 2.48623C24.6528 2.40418 24.5959 2.30398 24.5659 2.19464C24.5359 2.0853 24.5337 1.97027 24.5596 1.85989C24.5828 1.78082 24.6238 1.708 24.6794 1.64687C24.7349 1.58574 24.8037 1.53788 24.8805 1.50686L27.287 0.0833678C27.4177 0.0170614 27.5649 -0.0106034 27.711 0.00365207H27.7683C29.6794 0.412909 31.3629 1.5282 32.4781 3.12395C33.5818 4.69315 34.4181 6.43218 34.9534 8.27131C35.2218 8.78335 35.5966 9.2329 36.0529 9.5902C36.5093 9.9475 37.0368 10.2044 37.6005 10.3439C38.1047 10.5034 38.6663 10.6742 39.3424 10.8564C39.6518 10.8564 39.9612 10.9475 40.2477 10.993C41.004 11.0727 43.5939 11.8016 43.8918 11.8813H44.0637H44.1783C44.9914 12.1513 45.7841 12.4786 46.5504 12.8607C47.1922 13.1454 54.5721 16.7439 55.7753 28.0522C56.0255 30.5762 55.9909 33.1202 55.6722 35.6366C55.6722 36.0238 55.6722 36.4337 55.6722 36.8551V36.9576C53.8387 48.5733 54.022 72.0667 66.2723 86.5635C66.2723 86.5635 68.7475 89.7066 66.1806 92.6105C65.7468 93.0665 65.2247 93.4307 64.6458 93.6811C64.0668 93.9316 63.4429 94.0633 62.8115 94.0682C60.5973 94.0887 58.3984 93.7026 56.3254 92.9294C52.014 91.6264 47.8891 89.7778 44.0522 87.429C42.0366 86.2668 40.1031 84.9694 38.2652 83.5457L37.4172 82.8738C36.5806 82.2247 35.7899 81.5984 35.2628 81.1087C33.2594 79.4376 31.4249 77.5764 29.7851 75.5514H29.7278C29.407 75.13 29.0059 74.6517 28.5819 74.1962C27.9974 73.4902 27.4359 72.8296 27.1838 72.4311C25.0824 69.7021 23.259 66.7724 21.7405 63.6851L19.3684 58.5036C17.6534 54.4864 16.2773 50.3345 15.2545 46.0907C13.9687 40.7665 13.5929 35.2654 14.1429 29.8173Z"
        fill={colorTheme.topIconColor}
        data-testid="top-icon"
      />
      <mask id="mask0_5123_1414" maskUnits="userSpaceOnUse" x="0" y="54" width="310" height="47">
        <path d="M0 54.5156L310 99.5799V100.481H0V54.5156Z" fill="white" />
      </mask>
      <g mask="url(#mask0_5123_1414)">
        <path
          d="M14.1429 29.8173V29.7604C14.1483 29.7418 14.1483 29.722 14.1429 29.7034C14.5029 26.5134 15.5211 23.4314 17.1338 20.65C19.0952 17.7921 21.6756 15.4069 24.6856 13.6692C25.4076 13.1226 26.0493 12.6215 26.6452 12.1091C27.1544 11.738 27.5681 11.2527 27.8529 10.6926C28.1376 10.1326 28.2853 9.51371 28.2839 8.88626C28.2747 8.6322 28.2362 8.38003 28.1693 8.13465C28.1693 8.07771 28.1693 8.03216 28.1693 7.98661V7.9069C27.7073 5.75174 26.4886 3.83027 24.7315 2.48623C24.6528 2.40418 24.5959 2.30398 24.5659 2.19464C24.5359 2.0853 24.5337 1.97027 24.5596 1.85989C24.5828 1.78082 24.6238 1.708 24.6794 1.64687C24.7349 1.58574 24.8037 1.53788 24.8805 1.50686L27.287 0.0833678C27.4177 0.0170614 27.5649 -0.0106034 27.711 0.00365207H27.7683C29.6794 0.412909 31.3629 1.5282 32.4781 3.12395C33.5818 4.69315 34.4181 6.43218 34.9534 8.27131C35.2218 8.78335 35.5966 9.2329 36.0529 9.5902C36.5093 9.9475 37.0368 10.2044 37.6005 10.3439C38.1047 10.5034 38.6663 10.6742 39.3424 10.8564C39.6518 10.8564 39.9612 10.9475 40.2477 10.993C41.004 11.0727 43.5939 11.8016 43.8918 11.8813H44.0637H44.1783C44.9914 12.1513 45.7841 12.4786 46.5504 12.8607C47.1922 13.1454 54.5721 16.7439 55.7753 28.0522C56.0255 30.5762 55.9909 33.1202 55.6722 35.6366C55.6722 36.0238 55.6722 36.4337 55.6722 36.8551V36.9576C53.8387 48.5733 54.022 72.0667 66.2723 86.5635C66.2723 86.5635 68.7475 89.7066 66.1806 92.6105C65.7468 93.0665 65.2247 93.4307 64.6458 93.6811C64.0668 93.9316 63.4429 94.0633 62.8115 94.0682C60.5973 94.0887 58.3984 93.7026 56.3254 92.9294C52.014 91.6264 47.8891 89.7778 44.0522 87.429C42.0366 86.2668 40.1031 84.9694 38.2652 83.5457L37.4172 82.8738C36.5806 82.2247 35.7899 81.5984 35.2628 81.1087C33.2594 79.4376 31.4249 77.5764 29.7851 75.5514H29.7278C29.407 75.13 29.0059 74.6517 28.5819 74.1962C27.9974 73.4902 27.4359 72.8296 27.1838 72.4311C25.0824 69.7021 23.259 66.7724 21.7405 63.6851L19.3684 58.5036C17.6534 54.4864 16.2773 50.3345 15.2545 46.0907C13.9687 40.7665 13.5929 35.2654 14.1429 29.8173Z"
          fill={colorTheme.bottomIconColor}
          data-testid="bottom-icon"
        />
      </g>
    </svg>
  );
};

PepperDecal.propTypes = {
  colorTheme: PropTypes.shape({
    bottomIconColor: PropTypes.string.isRequired,
    diagonalColor: PropTypes.string.isRequired,
    topIconColor: PropTypes.string.isRequired,
  }).isRequired,
};

export { PepperDecal };
