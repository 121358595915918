import { breadcrumbPropTypes } from '../../prop-types';
import { Flex } from '../grid';
import { Icon } from '../icons';
import { Link } from '../link';
import { P } from '../typography';

const ICON_SIZE = { xs: '1.3rem', md: '1.2rem', xxl: '0.9rem' };

const Breadcrumb = ({ ancestor }) => (
  <Flex alignItems="center" alignContent="center" alignItem="center">
    <Link href={ancestor.url}>
      <P variant={4} color="grey700">
        {ancestor.title}
      </P>
    </Link>
    <Icon fill="grey700" stroke="none" name="chevron" rotate="-90deg" height={ICON_SIZE} width={ICON_SIZE} />
  </Flex>
);

Breadcrumb.propTypes = {
  ancestor: breadcrumbPropTypes,
};

Breadcrumb.defaultProps = {
  ancestor: null,
};

export { Breadcrumb };
